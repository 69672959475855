import React from "react";

export const InvizLogo = () => {
  return (
    <>
      <svg
        className="inviz-logo"
        width="98"
        height="34"
        viewBox="0 0 98 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.984572 32.0736C0.32819 31.4272 0 30.5499 0 29.4417V3.5554C0 2.48185 0.32819 1.61609 0.984572 0.969654C1.64095 0.323218 2.53176 0 3.65698 0C4.78221 0 5.67301 0.323218 6.32939 0.969654C6.98577 1.61609 7.31397 2.47608 7.31397 3.5554V29.4417C7.31397 30.5499 6.98577 31.4272 6.32939 32.0736C5.67301 32.7201 4.78221 33.0433 3.65698 33.0433C2.53176 33.0433 1.64095 32.7201 0.984572 32.0736Z"
          fill="black"
        />
        <path
          d="M13.6375 32.0511C14.2763 32.7149 15.1143 33.0438 16.1458 33.0438C17.1421 33.0438 17.9567 32.7149 18.5779 32.0511C19.205 31.3931 19.5156 30.5389 19.5156 29.4942V12.1905L35.216 31.8433C35.8372 32.6456 36.728 33.0438 37.8825 33.0438C38.914 33.0438 39.752 32.7149 40.3908 32.0511C41.0121 31.4104 41.3344 30.5909 41.3461 29.5866L18.7889 1.13184C18.1149 0.381511 17.2358 0.00634766 16.1458 0.00634766C15.1143 0.00634766 14.2763 0.335337 13.6375 0.999088C12.9987 1.65707 12.6763 2.5286 12.6763 3.60792V29.4942C12.6763 30.5389 12.9987 31.3931 13.6375 32.0511Z"
          fill="black"
        />
        <path
          d="M53.5478 0.9986C52.909 0.334849 52.0709 0.00585938 51.0395 0.00585938C50.0432 0.00585938 49.2285 0.334849 48.6073 0.9986C47.9802 1.65658 47.6696 2.5108 47.6696 3.55549V20.8592L31.9692 1.20638C31.348 0.40411 30.4572 0.00585938 29.3027 0.00585938C28.2712 0.00585938 27.4332 0.334849 26.7944 0.9986C26.1731 1.63926 25.8508 2.45885 25.8391 3.46314L48.3963 31.9179C49.0703 32.6682 49.9494 33.0434 51.0395 33.0434C52.0709 33.0434 52.909 32.7144 53.5478 32.0506C54.1866 31.3926 54.5089 30.5211 54.5089 29.4418V3.55549C54.5089 2.5108 54.1866 1.65658 53.5478 0.9986Z"
          fill="black"
        />
        <path
          d="M60.7507 32.0736C60.0943 31.4272 59.7661 30.5499 59.7661 29.4417V3.5554C59.7661 2.48185 60.0943 1.61609 60.7507 0.969654C61.4071 0.323218 62.2979 0 63.4231 0C64.5483 0 65.4391 0.323218 66.0955 0.969654C66.7519 1.61609 67.0801 2.47608 67.0801 3.5554V29.4417C67.0801 30.5499 66.7519 31.4272 66.0955 32.0736C65.4391 32.7201 64.5483 33.0433 63.4231 33.0433C62.2979 33.0433 61.4071 32.7201 60.7507 32.0736Z"
          fill="black"
        />
        <path
          d="M97.1561 27.7795C97.7187 28.2413 98 28.9512 98 29.9035C98 30.8905 97.7187 31.612 97.1561 32.0737C96.5935 32.5355 95.7203 32.7663 94.5306 32.7663H75.179C74.212 32.7663 73.4385 32.4835 72.8583 31.9121C72.2781 31.3407 71.9909 30.6308 71.9909 29.765C71.9909 28.8762 72.3015 28.0104 72.9286 27.1793L88.5352 5.91036H75.5072C74.3175 5.91036 73.4443 5.67949 72.8817 5.21775C72.3191 4.75601 72.0378 4.05186 72.0378 3.09375C72.0378 2.10678 72.3191 1.38531 72.8817 0.923568C73.4443 0.461828 74.3175 0.230957 75.5072 0.230957H94.1555C95.1225 0.230957 95.8961 0.513773 96.4763 1.08518C97.0565 1.65658 97.3436 2.37228 97.3436 3.23227C97.3436 4.15575 97.0447 5.01574 96.4528 5.81801L80.8052 27.0927H94.5306C95.7203 27.0927 96.5935 27.3236 97.1561 27.7795Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export const ArrowDown = () => {
  return (
    <>
      <svg
        className="arrow-down"
        width="22"
        height="29"
        viewBox="0 0 22 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.5007 0.488892C10.8985 0.488892 11.28 0.674729 11.5613 1.00552C11.8426 1.33631 12.0006 1.78496 12.0006 2.25277V22.6891L18.4379 15.115C18.5773 14.951 18.7429 14.8209 18.925 14.7322C19.1072 14.6434 19.3025 14.5977 19.4997 14.5977C19.6969 14.5977 19.8922 14.6434 20.0744 14.7322C20.2566 14.8209 20.4222 14.951 20.5616 15.115C20.7011 15.279 20.8117 15.4737 20.8872 15.688C20.9626 15.9023 21.0015 16.1319 21.0015 16.3638C21.0015 16.5958 20.9626 16.8254 20.8872 17.0397C20.8117 17.254 20.7011 17.4487 20.5616 17.6127L11.5626 28.196C11.4233 28.3602 11.2578 28.4906 11.0756 28.5795C10.8934 28.6684 10.698 28.7142 10.5007 28.7142C10.3035 28.7142 10.1081 28.6684 9.9259 28.5795C9.74368 28.4906 9.57817 28.3602 9.43885 28.196L0.439846 17.6127C0.300398 17.4487 0.189781 17.254 0.114312 17.0397C0.0388435 16.8254 0 16.5958 0 16.3638C0 16.1319 0.0388435 15.9023 0.114312 15.688C0.189781 15.4737 0.300398 15.279 0.439846 15.115C0.721475 14.7838 1.10345 14.5977 1.50173 14.5977C1.69894 14.5977 1.89422 14.6434 2.07642 14.7322C2.25861 14.8209 2.42416 14.951 2.56361 15.115L9.0009 22.6891V2.25277C9.0009 1.78496 9.15892 1.33631 9.44019 1.00552C9.72146 0.674729 10.103 0.488892 10.5007 0.488892Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export const CaretDown = () => {
  return (
    <svg
      className="caret-down"
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.175 0L5 3.81667L8.825 0L10 1.175L5 6.175L0 1.175L1.175 0Z"
        fill="black"
      />
    </svg>
  );
};

export const LinkedinIcon = () => {
  return (
    <svg
      className="LinkedinIcon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.719C0 0.7695 0.789 0 1.7625 0H22.2375C23.211 0 24 0.7695 24 1.719V22.281C24 23.2305 23.211 24 22.2375 24H1.7625C0.789 24 0 23.2305 0 22.281V1.719ZM7.4145 20.091V9.2535H3.813V20.091H7.4145ZM5.6145 7.773C6.87 7.773 7.6515 6.942 7.6515 5.901C7.629 4.8375 6.8715 4.029 5.6385 4.029C4.4055 4.029 3.6 4.839 3.6 5.901C3.6 6.942 4.3815 7.773 5.5905 7.773H5.6145ZM12.9765 20.091V14.0385C12.9765 13.7145 13.0005 13.3905 13.0965 13.1595C13.356 12.513 13.9485 11.8425 14.9445 11.8425C16.248 11.8425 16.7685 12.8355 16.7685 14.2935V20.091H20.37V13.875C20.37 10.545 18.594 8.997 16.224 8.997C14.313 8.997 13.4565 10.047 12.9765 10.7865V10.824H12.9525C12.9605 10.8115 12.9685 10.799 12.9765 10.7865V9.2535H9.3765C9.4215 10.2705 9.3765 20.091 9.3765 20.091H12.9765Z"
        fill="#0664C9"
      />
    </svg>
  );
};

export const LinkedinIconFooter = () => {
  return (
    <svg
      className="linkedin-footer"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.28925C0 0.577125 0.59175 0 1.32187 0H16.6781C17.4082 0 18 0.577125 18 1.28925V16.7108C18 17.4229 17.4082 18 16.6781 18H1.32187C0.59175 18 0 17.4229 0 16.7108V1.28925ZM5.56087 15.0683V6.94013H2.85975V15.0683H5.56087ZM4.21088 5.82975C5.1525 5.82975 5.73862 5.2065 5.73862 4.42575C5.72175 3.62813 5.15363 3.02175 4.22888 3.02175C3.30413 3.02175 2.7 3.62925 2.7 4.42575C2.7 5.2065 3.28613 5.82975 4.19287 5.82975H4.21088V5.82975ZM9.73238 15.0683V10.5289C9.73238 10.2859 9.75037 10.0429 9.82237 9.86962C10.017 9.38475 10.4614 8.88187 11.2084 8.88187C12.186 8.88187 12.5764 9.62663 12.5764 10.7201V15.0683H15.2775V10.4062C15.2775 7.90875 13.9455 6.74775 12.168 6.74775C10.7347 6.74775 10.0924 7.53525 9.73238 8.08987V8.118H9.71438C9.72035 8.10861 9.72635 8.09923 9.73238 8.08987V6.94013H7.03237C7.06612 7.70288 7.03237 15.0683 7.03237 15.0683H9.73238Z"
        fill="#0664C9"
      />
    </svg>
  );
};

export const TwitterIconFooter = () => {
  return (
    <svg
      className="twitter-footer"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.65425 14.6258C12.447 14.6258 16.1629 8.99741 16.1629 4.12503C16.1629 3.96753 16.1629 3.80778 16.1561 3.65028C16.8796 3.12658 17.5039 2.4782 18 1.73553C17.3242 2.03349 16.6081 2.22986 15.8749 2.31828C16.6472 1.85662 17.2257 1.13024 17.5027 0.274155C16.7771 0.704044 15.9829 1.00583 15.1549 1.16628C14.5982 0.573431 13.8616 0.180687 13.0591 0.0488819C12.2566 -0.0829233 11.4331 0.0535664 10.716 0.437209C9.99895 0.820852 9.42841 1.43024 9.09276 2.17097C8.7571 2.91171 8.67508 3.74245 8.85938 4.53453C7.39096 4.4609 5.95442 4.07943 4.64292 3.41487C3.33143 2.7503 2.17427 1.81748 1.2465 0.676905C0.775508 1.49037 0.63177 2.45261 0.844477 3.3682C1.05718 4.2838 1.61039 5.08413 2.39175 5.60666C1.80623 5.58676 1.23359 5.42953 0.72 5.14766V5.19828C0.72101 6.05045 1.01622 6.87613 1.55573 7.53576C2.09525 8.19539 2.84597 8.64851 3.681 8.81853C3.36405 8.90585 3.03663 8.94938 2.70788 8.94791C2.47611 8.94861 2.2448 8.92714 2.01712 8.88378C2.25313 9.61735 2.71268 10.2587 3.3314 10.7181C3.95012 11.1774 4.69703 11.4317 5.4675 11.4454C4.15862 12.4734 2.54183 13.031 0.8775 13.0283C0.584236 13.0295 0.291176 13.0126 0 12.9777C1.6892 14.0546 3.65096 14.6264 5.65425 14.6258V14.6258Z"
        fill="#0664C9"
      />
    </svg>
  );
};

export const FacebookIconFooter = () => {
  return (
    <svg
      className="facebook-footer"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 9.05512C18 4.05399 13.9708 0 9.00112 0C4.02925 0.00112486 0 4.05399 0 9.05624C0 13.5748 3.29134 17.3206 7.5928 18V11.6727H5.30934V9.05624H7.59505V7.05962C7.59505 4.79078 8.93926 3.53768 10.9944 3.53768C11.9798 3.53768 13.009 3.71429 13.009 3.71429V5.94151H11.874C10.757 5.94151 10.4083 6.64005 10.4083 7.35658V9.05512H12.9033L12.5051 11.6715H10.4072V17.9989C14.7087 17.3195 18 13.5737 18 9.05512Z"
        fill="#0664C9"
      />
    </svg>
  );
};
