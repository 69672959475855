import * as React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import Container from "./Container";

import {
  InvizLogo,
  LinkedinIconFooter,
  TwitterIconFooter,
  FacebookIconFooter,
} from "../../images/svgs";

// markup
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={classNames("pageFooter")}>
      <div className="footerNav">
        <Container>
          <nav>
            <ul>
              <li className="hasChild">
                <div className={classNames("noLink")}>
                  <span>Solutions</span>
                </div>

                <ul className="childMenu">
                  <li>
                    <Link to="/capabilities/artificial-intelligence-and-machine-learning">
                      AI &amp; ML
                    </Link>
                  </li>
                  <li>
                    <Link to="/capabilities/cloud-engineering">
                      Cloud Engineering
                    </Link>
                  </li>
                  <li>
                    <Link to="/capabilities/data-engineering">
                      Data Engineering
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="hasChild">
                <div className={classNames("noLink")}>
                  <span>Resources</span>
                </div>
                <ul className="childMenu">
                  <li>
                    <Link to="/case-studies">Case Studies</Link>
                  </li>
                </ul>
              </li>

              <li className="hasChild">
                <div className={classNames("noLink")}>
                  <span>Company</span>
                </div>
                <ul className="childMenu">
                  <li>
                    <Link to="/about-inviz-ai-solutions">About Us</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </Container>
      </div>
      <div className="footerBranding">
        <Container>
          <div className="logo">
            <InvizLogo />
          </div>
          <div className="tagline">
            <p>
              Unlock your business potential with our Artificial Intelligence -
              Machine Learning accelerators and expertise
            </p>
          </div>
        </Container>
      </div>
      <div className="footerInfo">
        <Container>
          <ul className="social-links">
            <li>
              <a href="https://www.linkedin.com/company/inviz-ai">
                <LinkedinIconFooter />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/inviz_ai">
                <TwitterIconFooter />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Invizai">
                <FacebookIconFooter />
              </a>
            </li>
          </ul>
          <ul className="policies">
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
            </li>
            <li className="copy-right">
              <div>
                &copy;&nbsp;{year} Inviz AI Solutions.&nbsp;&nbsp;All Rights
                Reserved.
              </div>
            </li>
          </ul>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
