import * as React from "react";
import classNames from "classnames";
import Container from "./Container";
// import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "gatsby";

import BtnFilled from "../reusable/BtnFilled";
import { InvizLogo } from "../../images/svgs";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";
// markup
const Header = ({ menu, toggleMenu }) => {
  const breakpoints = useBreakpoint();
  return (
    <>
      <header className={classNames("pageHeader")}>
        <Container>
          <Link to="/">
            <InvizLogo />
          </Link>

          {!breakpoints.md && <DesktopMenu />}
          <div className={classNames("right-links")}>
            <div className="contactLink">
              <BtnFilled to="/contact-us">Get in Touch</BtnFilled>
            </div>
            <div className="menuToggle" onClick={() => toggleMenu(!menu)}>
              <span />
            </div>
          </div>
        </Container>
      </header>
      {breakpoints.md && <MobileMenu menu={menu} />}
    </>
  );
};

export default Header;
