import * as React from "react";
// import { useState } from "react";
import classNames from "classnames";

import { Link } from "gatsby";

// import { CaretDown } from "../../images/svgs";
// markup
const MobileMenu = ({ menu }) => {
  /*
  const [solutions, toggleSolutions] = useState(false);
  const [studies, toggleStudies] = useState(false);
*/
  return (
    <>
      <div className={classNames("mobile-menu-overlay", menu && "open")}></div>
      <div className={classNames("mobile-menu", menu && "open")}>
        <nav>
          <ul>
            <li className="hasChild">
              {/*
              <div className={classNames("noLink")}>
                <span>Solutions</span>
              </div>
              */}

              <ul className="childMenu">
                <li>
                  <Link to="/capabilities/artificial-intelligence-and-machine-learning">
                    AI &amp; ML
                  </Link>
                </li>
                <li>
                  <Link to="/capabilities/cloud-engineering">
                    Cloud Engineering
                  </Link>
                </li>
                <li>
                  <Link to="/capabilities/data-engineering">
                    Data Engineering
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/case-studies">Case Studies</Link>
            </li>

            <li>
              <Link to="/about-inviz-ai-solutions">About Us</Link>
            </li>

            <li>
              <Link to="/careers">Careers</Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default MobileMenu;
