import * as React from "react";
import classNames from "classnames";
import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import Header from "./Header";
import Footer from "./Footer";

import "./../../sass/styles.scss";

// markup
const Page = ({
  children,
  className,
  metaTitle = "Inviz AI Solution",
  metaDescription = "",
}) => {
  const [menu, toggleMenu] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 30) {
          document.body.classList.add("scrolled");
        } else {
          document.body.classList.remove("scrolled");
        }
      });
    }
  }, []);

  return (
    <div className={classNames("page", menu && "menuOpen", className)}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta
          property="og:image"
          content="https://www.inviz.ai/inviz-ai-solutions.png"
        />
        <meta
          name="twitter:image"
          content="https://www.inviz.ai/inviz-ai-solutions.png"
        ></meta>
      </Helmet>
      <Header menu={menu} toggleMenu={toggleMenu} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Page;
