import * as React from "react";
import { useState } from "react";
import classNames from "classnames";

import { Link } from "gatsby";

import { CaretDown } from "../../images/svgs";
// markup
const DesktopMenu = () => {
  const [solutions, toggleSolutions] = useState(false);
  const [studies, toggleStudies] = useState(false);

  const solutionsMenu = () => {
    toggleSolutions(!solutions);
    toggleStudies(false);
  };
  const studiesMenu = () => {
    toggleSolutions(false);
    toggleStudies(!studies);
  };

  return (
    <>
      <div className={classNames("desktop-menu")}>
        <nav>
          <ul>
            <li className="hasChild">
              <div
                className={classNames("noLink", solutions && "active")}
                onClick={solutionsMenu}
              >
                <span>Capabilities</span> <CaretDown />
              </div>

              {solutions && (
                <ul className="childMenu">
                  <li>
                    <Link to="/capabilities/artificial-intelligence-and-machine-learning">
                      AI &amp; ML
                    </Link>
                  </li>
                  <li>
                    <Link to="/capabilities/cloud-engineering">
                      Cloud Engineering
                    </Link>
                  </li>
                  <li>
                    <Link to="/capabilities/data-engineering">
                      Data Engineering
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/case-studies">Case Studies</Link>
            </li>
            {/*
            <li className="hasChild">
              <div
                className={classNames("noLink", studies && "active")}
                onClick={studiesMenu}
              >
                <span>Case Studies </span> <CaretDown />
              </div>
              {studies && (
                <ul className="childMenu">
                  <li>
                    <Link to="/case-studies/intelligent-product-discovery-platform">
                      Falabella
                    </Link>
                  </li>
                  <li>
                    <Link to="/case-studies/machine-learning-for-ai-powered-search">
                      Lowe's
                    </Link>
                  </li>
                  <li>
                    <Link to="/case-studies/clickstream-data-platform">
                      Tata Cliq
                    </Link>
                  </li>
                </ul>
              )}
            </li>
              */}
            <li>
              <Link to="/about-inviz-ai-solutions">About Us</Link>
            </li>

            <li>
              <Link to="/careers">Careers</Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default DesktopMenu;
