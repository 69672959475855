import * as React from "react";
import classNames from "classnames";

import { AnchorLink } from "gatsby-plugin-anchor-links";
// markup
const BtnFilled = ({
  children,
  to,
  title = "",
  className = "",
  color = "blue",
  context = "internal",
}) => {
  return (
    <>
      {context === "internal" && (
        <AnchorLink
          to={to}
          title={title}
          className={classNames("btn", "btn-filled", className, color)}
        >
          <span>{children}</span>
        </AnchorLink>
      )}
      {context === "external" && (
        <a
          href={to}
          title={title}
          className={classNames("btn", "btn-filled", className, color)}
        >
          <span>{children}</span>
        </a>
      )}
    </>
  );
};

export default BtnFilled;
